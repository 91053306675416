import { CONTACT, PATHS } from "../../config/urls";
import {
  Disclaimer,
  GetInTouchButton,
  GetInTouchContainer,
  GetInTouchDiv,
  GetInTouchLayout,
} from "./styles";

import LifeEMTlogo from "../../images/life-ride-w-logo.svg";
import { Link } from "gatsby";
import React from "react";

const GetInTouchSection = () => {
  return (
    <GetInTouchLayout>
      <GetInTouchContainer>
          <GetInTouchDiv id="contact_us">
            <Link to={PATHS.INDEX}>
              <img src={LifeEMTlogo} alt="Life EMT logo" />
            </Link>
            <GetInTouchButton>
              70 South Orange Ave, Suite 220, Livingston, NJ 07039 (551) 225-0080
            </GetInTouchButton>
          </GetInTouchDiv>
      </GetInTouchContainer>
      <Disclaimer>
      Life Ride, Life Home Care, Life DME, Life Home Therapy, Life Hospice, Life Health Group logos are all Registered in U.S. Patent and Trademark Oﬃce
      </Disclaimer>
   </GetInTouchLayout> 
  );
};

export default GetInTouchSection;
