import {
  DESKTOP_1024,
  MOBILE_460,
  TABLET_740,
  TABLET_768,
  TABLET_800,
  TABLET_992,
} from "../../styles/globals/sizes";

import { COLORS } from "../../styles/globals/colors";
import styled from "styled-components";

export const PhoneIcon = styled.img`
  align-self: center;
  margin: 6px 25px 0;
  @media (max-width: ${TABLET_992}px) {
    margin: 0;
    padding-right: 20px;
    align-self: center;
    position: relative;
    left: -20px;
  }
  @media (max-width: ${TABLET_768}px) {
    text-align: center;
  }
  @media (max-width: ${TABLET_740}px) {
    margin: 20px 0 10px;
    padding: 0;
    position: static;
  }
`;

export const GetInTouchContainer = styled.div`
  
  width: 100%;
  //height: 142px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }
`;

export const GetInTouchDiv = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  padding: 50px;
  img {
    width: 140px;
    height: auto;
  }

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const GetInTouchButton = styled.div`
  align-self: center;
  width: 35%;
  font: 400 20px/28px Roboto;
  border-radius: 24px;
  color: ${COLORS.white};
  
  @media (max-width: ${DESKTOP_1024}px) {
    padding: 10px 60px;
    margin: 0;
  }
  @media (max-width: ${TABLET_800}px) {
    padding: 0;
    margin: 20px auto 0;
    text-align: center;
    width: 95%;
  }
  @media (max-width: ${MOBILE_460}px) {
    //display: ${(props) =>  props.mobile ? "flex" : "none"} !important;
  }
`;

export const GetInTouchLayout = styled.div`
  
  width: 100%;
  background: transparent linear-gradient(265deg, #ED67A6 0%, #EC4746 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  flex: 1;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
  }
`;

export const Disclaimer = styled.p`
  font: 400 15px/20px "Roboto";
  color: ${COLORS.white};
  margin: 0 auto;
  padding: 0 10px 10px;

  width: 100%;
  max-width: 1009px;

  @media (max-width: ${TABLET_992}px) {
    padding-bottom: 0;
  }
  @media (max-width: 740px) {
    font-size: 14px;
  }
`;